import React, { useCallback } from 'react';
import { useQuery, useReactiveVar } from '@apollo/client';
import { preofferSelectedQuery, FirstLoadOfInvoicesQuery } from '@fingo/lib/apollo/cache/localQuery';
import { preofferSelected, currencyVar } from '@fingo/lib/apollo/reactive-variables';
import { AVAILABLE_FACTORING } from '@fingo/lib/graphql';
import { DocumentList } from '@fingo/lib/components/lists';
import { getHelpVideoLink, useInvoicePreColumns } from '@fingo/lib/constants';
import { useNewInvoiceCharged } from '@fingo/lib/hooks';
import { NoDocumentError } from './noDataMessages';

const NoDataError = () => <NoDocumentError document="invoice" />;

const AvailableFactoring = () => {
  const { data: { selectedPreofferIds } } = useQuery(preofferSelectedQuery);
  const [newInvoices, readNewInvoices] = useNewInvoiceCharged();
  const { data: { firstLoad } } = useQuery(FirstLoadOfInvoicesQuery);
  const isRowSelectable = useCallback(
    ({ row }) => row.preofferSelectable?.selectable,
    [],
  );
  const currency = useReactiveVar(currencyVar);

  return (
    <DocumentList
      initialOrderBy="preoffer_MonthlyRate"
      trackerId="PREOFFERS"
      type="available-factoring"
      headerTitle="Financiamiento de facturas"
      queryDocument={AVAILABLE_FACTORING}
      customVariables={{
        currency,
      }}
      showFilters
      emitter
      checkboxSelection
      countryFilter
      shoppingCartPreview
      newData={newInvoices}
      readData={readNewInvoices}
      isRowSelectable={isRowSelectable}
      firstLoad={firstLoad}
      includeHeaders={[
        'folio',
        'dteType_Code',
        'dateIssued',
        'receiver_sortable',
        'amountWithIva',
        'preoffer_MonthlyRate',
        'defaultRate',
        'factoringStatus',
        'uploadDocuments',
      ]}
      mobileHeaders={[
        'folio',
        'receiver_sortable',
        'preoffer_MonthlyRate',
        'amountWithIva',
      ]}
      financingStatusOptions
      initialInvoiceFilters={{
        availableForFinancing: 'AVAILABLE',
        preoffer_Preofferevaluationrequest_Isnull: true,
      }}
      onSelectionModelChange={preofferSelected}
      selectionModel={selectedPreofferIds}
      keepNonExistentRowsSelected
      preColumns={useInvoicePreColumns()}
      helpVideo={getHelpVideoLink('available-factoring')}
      noRowsMessage={NoDataError}
      updateCustomVariables
    />
  );
};

export default AvailableFactoring;
